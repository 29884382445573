import { computed } from 'vue'
import { useStore } from 'vuex'

export default function(store) {
    const checkPermission = (permission) => {
        // const store = useStore()
        const user = computed(() => store.getters.user)
        const permissions = computed(() => store.getters.permissions)
        console.log(store, user);

        if (user.value.admin || permissions.value.includes(permission)) return true
        else return false
    }

    return {
        checkPermission
    }
}
